<template>
  <div class="home_section6">
    <div class="section6_contain">
      <div class="section6_top">
        <div class="sec6_left">
          <img
            :src="require('@/assets/images/car-logo.png')"
            alt="image"
            class="logo"
          />
          <br />
          <h4>07710 607 508</h4>
          <h4 id="h4">GET DIRECTION</h4>
          <ol>
            <router-link to="/about">
              <li>About</li>
            </router-link>
            <router-link to="/services">
              <li>Service</li>
            </router-link>
            <router-link to="/contact">
              <li>Contact</li>
            </router-link>
          </ol>
        </div>
        <div class="sec6_right">
          <h3>
            Newcastle Under Lime ST57EF unit8 Rosevale Road <br />
            <span
              ><a href="mailto:csmpaintandbody@gmail.com" target="_blank"
                >Click here to csmpaintandbody@gmail.com</a
              >
            </span>
          </h3>
          <h3>
            MON – FRI: 8:30 AM – 6:00 PM <br />
            SAT: 8:30 AM – 2:00 PM
          </h3>
        </div>
      </div>
      <div class="section6_bottom">
        <div class="sec6bot_left">
          <h3>© 2023 THEME BY Runtime Solutions</h3>
        </div>
        <div class="sec6bot_right">
          <div class="sec_link">
            <font-awesome-icon icon="fa-brands fa-facebook" />
            <span>FACEBOOK</span>
          </div>
          <div class="sec_link">
            <font-awesome-icon icon="fa-brands fa-twitter" />
            <span>TWITTER</span>
          </div>
          <div class="sec_link">
            <font-awesome-icon icon="fa-brands fa-yelp" />
            <span>YELP</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";

export default {
  name: "HomeFooter",
};
</script>

<style scoped>
.section6_contain {
  max-width: 1480px;
  margin: 0px auto;
}

.section6_top {
  padding: 60px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 770px) {
  .section6_top {
    flex-direction: column;
    align-items: start;
  }
}

.sec6_left {
  text-align: left;
}

.sec6_left img {
  width: 140px;
}

.logo {
  height: 20%;
  width: 20%;
}

.sec6_left h4 {
  font-size: 30px;
}

.sec6_left h4 {
  font-size: 30px;
  margin-bottom: 0px;
}

.sec6_left #h4 {
  margin-top: 0px;
  border-bottom: 1px solid black;
}

.sec6_left ol {
  display: flex;
  gap: 15px;
  padding-left: 0px;
}

.sec6_left ol li {
  margin-right: 10px;
  list-style: none;
  font-size: 10px;
  text-transform: uppercase;
  transition: 0.5s ease;
  cursor: pointer;
  font-weight: 500;
}

.sec6_left ol li:hover {
  color: red;
}

.sec6_right {
  display: flex;
  gap: 30px;
  text-align: left;
}

@media screen and (max-width: 700px) {
  .sec6_right {
    flex-direction: column;
  }
}

.sec6_right h3 {
  font-weight: 300;
  color: black;
  font-size: 15px;
}

.sec6_right h3 span {
  text-decoration: underline;
}

.section6_bottom {
  display: flex;
  justify-content: space-between;
  padding: 30px 30px;
  /* background-color: #023047; */
  /* background-color: #80ffdb; */
  background-color: transparent !important;
  /* color: white; */
  color: #023047;
}

.sec6bot_left h6 {
  font-weight: 300;
}

.sec6bot_right {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}

.sec_link {
  display: flex;
  align-items: center;
  gap: 10px;
}

.sec_link span {
  font-size: 10px;
}
@media screen and (max-width: 770px) {
  .section6_bottom {
    flex-direction: column;
    align-items: start;
  }
}
</style>
